body {
  background: rgb(0, 222, 195);
  background: linear-gradient(
    45deg,
    rgba(0, 222, 195, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
}
.main {
  display: flex;
}
.container {
  background-color: rgba(0, 255, 255, 0.459);
  color: black;
  /* text-align: center; */
  border: 2px solid black;
  box-shadow: 3px 3px 5px black;
  width: 20%;
  max-height: 70vh;
  min-height: 40vh;
  overflow: scroll;
  margin: 1rem 1rem 1rem 10rem;
  padding: 1rem;
  min-width: 300px;
}

#calc {
  height: 30vh;
}

.container h1 {
  position: relative;
}

.container h1::after {
  content: '';
}

.container-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: center;
  /* width: 50%; */
  margin: auto;
  /* overflow: scroll; */
}

.image img {
  margin: 1rem;
  width: 100%;
  border-radius: 10%;
  transition: 0.5s;
}

.image img:hover {
  transform: scale(1.3);
  transition: 0.5s;
}

@media only screen and (max-width: 900px) {
  .main {
    display: block;
  }
  .container {
    margin: 1rem auto;
  }
}
