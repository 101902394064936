/* .logo h1 {
  margin: 1rem;
} */

nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin: 0 5rem; */
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

nav button {
  border: none;
  color: white;
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
  transition: ease-in-out 0.25s;
}

nav button:hover {
  border-bottom: 1px solid white;
  transition: ease-in-out 0.25s;
  transform: scale(1.2);
}

.logo {
  font-size: 1rem;
  display: flex;
  width: fit-content;
  /* border: 1px solid black; */
}
