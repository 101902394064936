form {
  display: flex;
  flex-direction: column;
}

input {
  background-color: transparent;
  color: black;
  margin: 1rem;
  border: none;
  height: 3rem;
  font-size: 30px;
  text-align: center;
}

.result h2 {
  text-align: center;
}

.calc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calc h1 {
  text-align: center;
}
